@use '@angular/material' as mat;
@import './app-container.scss';

$sideNavWidth: 304px;

mat-sidenav-container {
  height: 100%;

  mat-sidenav.mat-drawer {
    border-right: none;
    width: $sideNavWidth;
    background-color: mat.get-color-from-palette($earnr-dark-blue, 800);
  }
  mat-sidenav-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    // background-color: #fff;
    overflow: hidden;

    @include app-container;

    // only the outher app-container should scroll so that we have a sticky header and footer
    app-page-container {
      overflow: auto !important;
    }
  }
}
