@use '@angular/material' as mat;
// https://material.angular.io/guide/typography


$desktop-typography: mat.define-legacy-typography-config(
  $font-family: 'Roboto, monospace',
  /* headers */
  $headline: mat.define-typography-level(28px, 32px, 500, $letter-spacing: normal), /* h1.mat-h1 */
  $title: mat.define-typography-level(20px, 24px, 500, $letter-spacing: 0.25px), /* h2.mat-h2 */
  $subheading-2: mat.define-typography-level(20px, 24px, 400),  /* h3.mat-h3 */
  $subheading-1: mat.define-typography-level(18px, 24px, 400), /* h4.mat-h4 */

  /* body */
  $body-1: mat.define-typography-level(16px, 24px, 400),
  $body-2: mat.define-typography-level(20px, 24px, 300),
  $caption: mat.define-typography-level(12px, 20px, 700),
  /* misc */
  $input: mat.define-typography-level(16px, 1.125, 400),
  $button: mat.define-typography-level(14px, 14px, 300),
);

$tablet-typography: mat.define-legacy-typography-config(
  $font-family: 'Roboto, monospace',
  /* headers */
  $headline: mat.define-typography-level(28px, 32px, 500, $letter-spacing: normal), /* h1.mat-h1 */
  $title: mat.define-typography-level(20px, 24px, 500, $letter-spacing: 0.25px), /* h2.mat-h2 */
  $subheading-2: mat.define-typography-level(20px, 24px, 500),  /* h3.mat-h3 */
  $subheading-1: mat.define-typography-level(18px, 24px, 400), /* h4.mat-h4 */

  /* body */
  $body-1: mat.define-typography-level(16px, 24px, 400),
  $body-2: mat.define-typography-level(20px, 24px, 300),
  $caption: mat.define-typography-level(12px, 20px, 700),
  /* misc */
  $input: mat.define-typography-level(16px, 1.125, 400),
  $button: mat.define-typography-level(14px, 14px, 300),
);

$mobile-typography: mat.define-legacy-typography-config(
  $font-family: 'Roboto, monospace',
  /* headers */
  $headline: mat.define-typography-level(20px, 28px, 500, $letter-spacing: 0.4px),  /* h1.mat-h1 */
  $title: mat.define-typography-level(18px, 24px, 400, $letter-spacing: 0.4px), /* h2.mat-h2 */
  $subheading-2: mat.define-typography-level(17px, 23px, 400, $letter-spacing: 0.4px), /* h3.mat-h3 */
  $subheading-1: mat.define-typography-level(17px, 23px, 400, $letter-spacing: 0.4px), /* h4.mat-h4 */

  /* body */
  /* $body-1: mat.define-typography-level(16px, 24px, 400), */
  $body-1: mat.define-typography-level(16px, 20px, 400, $letter-spacing: 0.4px),
  $body-2: mat.define-typography-level(16px, 20px, 500, $letter-spacing: 0.4px),
  $caption: mat.define-typography-level(12px, 20px, 700, $letter-spacing: 0.4px),
  /* misc */
  $input: mat.define-typography-level(16px, 1.125, 400),
  $button: mat.define-typography-level(13px, 14px, 300),
);

@mixin typograhpy() {
  @media screen and (max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape) {
    @include mat.all-legacy-component-typographies($mobile-typography);
  }
  @media screen and (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
    @include mat.all-legacy-component-typographies($tablet-typography);
  }
  @media screen and (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    @include mat.all-legacy-component-typographies($desktop-typography);
  }

  a {
    color: mat.get-color-from-palette($earnr-sky-blue, 500);
  }

  *.capitalize {
    text-transform: capitalize;
  }

  p, h1, h2, h3, h4, a, div {
    &.align-center {
      text-align: center;
    }
    &.align-left {
      text-align: left;
    }
    &.align-right {
      text-align: right;
    }
    &.bottom-margin-0 {
      margin-bottom: 0 !important;
    }
    &.bottom-margin-8 {
      margin-bottom: 8px !important;
    }
    &.bottom-margin-16 {
      margin-bottom: 16px !important;
    }
    &.bottom-margin-24 {
      margin-bottom: 24px !important;
    }
    &.top-margin-0 {
      margin-top: 0 !important;
    }
    &.top-margin-8 {
      margin-top: 8px !important;
    }
    &.top-margin-16 {
      margin-top: 16px !important;
    }
    &.top-margin-24 {
      margin-top: 24px !important;
    }
    .bold {
      font-weight: 500;
    }
  }


  @media screen and (max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape) {
    .f-17-w-300_xs {
      font-size: 17px !important;
      font-weight: 300 !important;
    }
    .f-17-w-400_xs {
      font-size: 17px !important;
      font-weight: 400 !important;
    }
    .f-17-w-500_xs {
      font-size: 17px !important;
      font-weight: 500 !important;
    }
    .f-16-w-300_xs {
      font-size: 16px !important;
      font-weight: 300 !important;
    }
    .f-16-w-400_xs {
      font-size: 16px !important;
      font-weight: 400 !important;
    }
    .f-16-w-500_xs {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }
  @media screen and (min-width: 600px) {
    .f-17-w-300_gt-xs {
      font-size: 17px !important;
      font-weight: 300 !important;
    }
    .f-17-w-400_gt-xs {
      font-size: 17px !important;
      font-weight: 400 !important;
    }
    .f-17-w-500_gt-xs {
      font-size: 17px !important;
      font-weight: 500 !important;
    }
    .f-16-w-300_gt-xs {
      font-size: 16px !important;
      font-weight: 300 !important;
    }
    .f-16-w-400_gt-xs {
      font-size: 16px !important;
      font-weight: 400 !important;
    }
    .f-16-w-500_gt-xs {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
    .f-17-w-300_md {
      font-size: 17px !important;
      font-weight: 300 !important;
    }
    .f-17-w-500_md {
      font-size: 17px !important;
      font-weight: 500 !important;
    }
    .f-16-w-300_md {
      font-size: 16px !important;
      font-weight: 300 !important;
    }
    .f-16-w-500_md {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }

  @media screen and (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    .f-17-w-300_lg {
      font-size: 17px !important;
      font-weight: 300 !important;
    }
    .f-17-w-500_lg {
      font-size: 17px !important;
      font-weight: 500 !important;
    }
    .f-16-w-300_lg {
      font-size: 16px !important;
      font-weight: 300 !important;
    }
    .f-16-w-500_lg {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }
}