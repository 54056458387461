
@import 'material-icons/iconfont/filled.scss';
@import 'material-icons/iconfont/outlined.scss';
@import 'assets/theming/core-theme.scss';

html, body { height: 100%; }
body {
  margin: 0;

  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */

  // this counteracts the rubber band effect
  &.is-mobile {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
}