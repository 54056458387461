// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// https://material.angular.io/guide/theming-your-components
@use "@angular/material" as mat;
@use "signup-theme.scss" as signupTheme;
@import "typography.scss";
@import "theme-colors.scss";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include typograhpy();

// @function earnr-theme-foreground() {
//   @return (
//     base:              black,
//     divider:           #fff,
//     dividers:          $dark-dividers,
//     disabled:          $dark-disabled-text,
//     disabled-button:   rgba(black, 0.26),
//     disabled-text:     $dark-disabled-text,
//     elevation:         black,
//     hint-text:         $dark-disabled-text,
//     secondary-text:    $dark-secondary-text,
//     icon:              rgba(black, 0.54),
//     icons:             rgba(black, 0.54),
//     text:              rgba(black, 1),
//     slider-min:        rgba(black, 0.87),
//     slider-off:        rgba(black, 0.26),
//     slider-off-active: rgba(black, 0.38),
//   );
// };

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $primary: mat.define-palette($earnr-sky-blue, 700);
// $accent: mat.define-palette($earnr-pink, 400, 100, 600);
// // The warn palette is optional (defaults to red).
// $warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$core-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($core-theme);

@media screen and (max-width: 599.99px) and (orientation: portrait),
  (max-width: 959.99px) and (orientation: landscape) {
  body {
    background-color: #252b47;
    color: $dark-primary-text-mobile;
  }
}
@media screen and (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait),
  (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
  body {
    background-color: #252b47;
    color: $dark-primary-text-mobile;
  }
}
@media screen and (min-width: 840px) and (orientation: portrait),
  (min-width: 1280px) and (orientation: landscape) {
  body {
    background-color: #252b47;
    color: $dark-primary-text;
  }
}

// import components last because it requires the $core-theme
@import "components/mat-button.scss";
@import "components/mat-form-field.scss";
@import "components/mat-button-toggle-group.scss";
@import "components/address-finder.scss";
@import "components/mat-dialog.scss";
@import "components/mat-menu.scss";
@import "components/mat-select.scss";
@import "components/mat-sidenav.scss";
@import "components/app-phone.scss";

@include mat-button;
@include mat-form-field;
@include mat-button-toggle-group;
@include address-finder;
@include mat-dialog;
@include mat-select;

// temp hack as long as we can't override the foreground of the theme
// https://github.com/angular/components/issues/6244#issuecomment-933371623
mat-card,
mat-hint:not(.mat-error),
mat-dialog-container,
mat-sidenav-container {
  color: inherit !important;
}

.m-t-16 {
  margin-top: 16px;
}
.m-b-16 {
  margin-bottom: 16px;
}

.m-t-24 {
  margin-top: 24px;
}
.m-b-24 {
  margin-bottom: 24px;
}

.m-b-32-important {
  margin-bottom: 32px !important;
}

.m-t-48-important {
  margin-top: 48px !important;
}

.m-t-32 {
  margin-top: 32px;
}
