@mixin mat-select {
  .mat-select-panel.international-phone {
    max-height: 50vh;
    mat-optgroup {
      mat-option.mat-option:not(.mat-option-multiple) {
        padding-left: 16px;
      }
    }
  }
}
