@use '@angular/material' as mat;
@import '../theme-colors.scss';


@mixin mat-button-toggle-group() {

  mat-button-toggle-group {
    border-radius: 56px !important;
    background-color: rgba(50, 114, 227, 0.05) !important;
    width: 100%;
    outline: none;
    border: none !important;

    mat-button-toggle {
      min-width: 120px;
      background-color: transparent !important;
      border-radius: 56px !important;
      border-left: none !important;

      &:not(.mat-button-toggle-checked) {
        color: mat.get-color-from-palette($primary) !important;
      }
      &.mat-button-toggle-checked {
        background-color: mat.get-color-from-palette($primary) !important;
        color: mat.get-color-from-palette($primary, default-contrast) !important;
      }
      .mat-button-toggle-focus-overlay {
        border-bottom: none !important;
      }
      &:hover .mat-button-toggle-focus-overlay {
        background-color: #3273e3 !important;
        opacity: .1 !important;
      }
      .mat-ripple {
        border-radius: 56px !important;
      }
    }

  }
}