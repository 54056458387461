@mixin mat-dialog {
  mat-dialog-container.mat-dialog-container {
    padding: 48px;
    position: relative;
    max-width: 450px;

    .mat-dialog-title {
      button {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  body.is-mobile {
    mat-dialog-container.mat-dialog-container {
      padding: 24px;
    }
  }

  .cdk-overlay-pane.notice-dialog {
    mat-dialog-container.mat-dialog-container {
      padding: 24px;
    }
  }

  .cdk-overlay-pane.preview-add-funds-dialog {
    mat-dialog-container.mat-dialog-container {
      padding: 24px;
    }
  }

  .cdk-overlay-pane.returns-dialog {
    width: 400px;

    mat-dialog-container.mat-dialog-container {
      padding: 24px;
    }
  }

  .cdk-overlay-pane.welcome-dialog {
    width: 80%;
    height: 80%;

    mat-dialog-container.mat-dialog-container {
      padding: 48px;
    }
  }

  .cdk-overlay-pane.preview-transfer-dialog {
    mat-dialog-container.mat-dialog-container {
      padding: 24px;
      // padding-top: 7px;
    }
  }

  body.is-mobile {
    .cdk-overlay-pane.notice-dialog {
      width: 80%;
    }

    .cdk-overlay-pane.preview-transfer-dialog {
      min-width: 88%;
      position: relative !important;
      top: -80px;

      mat-dialog-container.mat-dialog-container {
        padding: 0px;
        padding-top: 7px;
      }
    }

    .cdk-overlay-pane.interest-period-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 34.5px;
      }
    }

    .cdk-overlay-pane.preview-add-funds-dialog {
      min-width: 88%;

      mat-dialog-container.mat-dialog-container {
        padding: 0px;
        padding-top: 7px;
      }
    }

    .cdk-overlay-pane.confirm-delete-transfer-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 38.5px;
      }
    }

    .cdk-overlay-pane.confirm-cancel-investment-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 38.5px;
      }
    }

    .cdk-overlay-pane.confirm-delete-withdrawal-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 38.5px;
      }
    }

    .cdk-overlay-pane.deny-transfer-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 38.5px;
      }
    }

    .cdk-overlay-pane.confirm-transfer-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 38.5px;
      }
    }

    .cdk-overlay-pane.confirm-withdrawal-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 38.5px;
      }
    }

    .cdk-overlay-pane.monthly-interest-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 34.5px;
      }
    }

    .cdk-overlay-pane.authorise-transfer-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 34.5px;
      }
    }

    .cdk-overlay-pane.mfa-dialog {
      mat-dialog-container.mat-dialog-container {
        padding-top: 34.5px;
      }
    }

    .cdk-overlay-pane.welcome-dialog {
      max-width: 100% !important;
      width: 95%;
      height: 90%;

      mat-dialog-container.mat-dialog-container {
        padding: 24px;
        border-radius: 0;
      }
    }
  }
}
