@mixin address-finder {
  .autocomplete-container {
    .af_list {
      z-index: 100;
      background-color: #fff;
      padding: 0;
      list-style: none;
      margin-top: -24px;
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

      li.af_item,
      li.af_empty {
        padding: 8px;
        &:hover {
          background-color: #ecebebde;
          cursor: pointer;
        }
      }
      li.af_footer {
        transform: scale(0);
        margin-top: -24px;
      }
    }
  }
}