@use "@angular/material" as mat;
@import "../theme-colors.scss";

// Override menu item font-size since MatDesign uses body-1, which is currently set at 16px;
.cdk-overlay-container {
  .mat-menu-panel {
    .mat-menu-item {
      &:hover {
        background-color: transparent;
        color: mat.get-color-from-palette($primary) !important;
      }
      span {
        font-size: 14px;
      }
    }
  }
}
