@use "@angular/material" as mat;
/*
  https://material.io/design/color/the-color-system.html#tools-for-picking-colors
  https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=001f4e&secondary.color=54d39c

  Available colours from Styleguide (which seem to incorrect):

  #4582fe
*/

$dark-primary-text: rgba(black, 1);
$dark-primary-text-mobile: #1c223f;

$light-primary-text: white;
$dirty-white: rgba(255, 255, 255, 0.7);

$earnr-sky-blue: (
  50: #bddeff,
  100: #bddeff,
  200: #92caff,
  300: #66b5ff,
  400: #46a4ff,
  500: #3094ff,
  600: #3286f7,
  700: #3273e3,
  /* P */ 800: #3161d0,
  900: #2e41b1,
  // A100: #8c9eff,
  // A200: #536dfe,
  // A400: #3d5afe,
  // A700: #304ffe,
  contrast:
    (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $light-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      // A100: $dark-primary-text,
      // A200: $light-primary-text,
      // A400: $light-primary-text,
      // A700: $light-primary-text,,
    ),
);

$earnr-native-blue: (
  50: #bddeff,
  100: #bddeff,
  200: #92caff,
  300: #66b5ff,
  400: #46a4ff,
  500: #3094ff,
  600: #3286f7,
  700: #1257d1,
  /* P */ 800: #3161d0,
  900: #2e41b1,
  // A100: #8c9eff,
  // A200: #536dfe,
  // A400: #3d5afe,
  // A700: #304ffe,
  contrast:
    (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $light-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      // A100: $dark-primary-text,
      // A200: $light-primary-text,
      // A400: $light-primary-text,
      // A700: $light-primary-text,,
    ),
);

$earnr-dark-blue: (
  50: #e6e8ed,
  100: #c0c6d5,
  200: #98a2b8,
  300: #737e9b,
  400: #566488,
  500: #3b4b77,
  600: #34446f,
  700: #2d3a64,
  800: #263157,
  900: #1c223f,
  /* P */ // A100: #8c9eff,
  // A200: #536dfe,
  // A400: #3d5afe,
  // A700: #304ffe,
  contrast:
    (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      // A100: $dark-primary-text,
      // A200: $light-primary-text,
      // A400: $light-primary-text,
      // A700: $light-primary-text,,
    ),
);

$earnr-green: (
  50: #e3f7ee,
  100: #bcecd4,
  200: #8ddfb8,
  300: #54d39c,
  /* S */ 400: #00c986,
  500: #00be71,
  600: #00ae66,
  700: #009b58,
  800: #008a4c,
  900: #006936,
  // A100: #8c9eff,
  // A200: #536dfe,
  // A400: #3d5afe,
  // A700: #304ffe,
  contrast:
    (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $light-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      // A100: $dark-primary-text,
      // A200: $light-primary-text,
      // A400: $light-primary-text,
      // A700: $light-primary-text,,
    ),
);

$earnr-pink: (
  50: #f6e2ef,
  100: #e9b6d8,
  200: #de87bf,
  300: #d557a5,
  400: #d12d8f,
  /* S */ 500: #cf0079,
  600: #be0074,
  700: #a8006d,
  800: #920066,
  900: #6c0059,
  // A100: #8c9eff,
  // A200: #536dfe,
  // A400: #3d5afe,
  // A700: #304ffe,
  contrast:
    (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $light-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      // A100: $dark-primary-text,
      // A200: $light-primary-text,
      // A400: $light-primary-text,
      // A700: $light-primary-text,,
    ),
);

$primary: mat.define-palette($earnr-sky-blue, 700);
$accent: mat.define-palette($earnr-pink, 400, 100, 600);
$warn: mat.define-palette($earnr-pink, 300);

// signup
$signup-dark-blue: #252b47;
$signup-super-light-grey: #979797;
$signup-primary-blue: #135ad9;
$native-warn-color: #db57a6;
