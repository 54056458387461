@use '@angular/material' as mat;
@import "../theme-colors.scss";

@mixin mat-form-field {

  .is-mobile {
    mat-form-field {
      mat-error,
      mat-hint {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  mat-form-field {
    &.no-hint {
      .mat-form-field-wrapper {
        margin-bottom: -1.25em;
      }
    }

    &.mat-form-field-should-float {
      .mat-form-field-label-wrapper {
        top: -0.9em;
      }
    }

    &.happy {
      .mat-form-field-outline {
        color: mat.get-color-from-palette($primary) !important;
      }
    }

    .mat-form-field-prefix, .mat-form-field-suffix {
      top: 5px;
    }

    input[type=number].mat-input-element {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &.mat-form-field-appearance-legacy {
      .mat-form-field-underline {
        background-color: #000;
      }

      .mat-form-field-label {
        color: rgba(0, 0, 0, 0.4);
      }

    }

    &.mat-form-field-appearance-outline {
      .mat-form-field-flex > .mat-form-field-infix {
        padding: 0.5em 0 1em 0 !important;
      }
      .mat-form-field-label-wrapper {
        top: -1.1em;
      }
      .mat-select-trigger {
        .mat-select-arrow-wrapper {
          transform: translateY(0);
        }
      }

      .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
        .mat-form-field-label {
        transform: translateY(-1.1em) scale(0.75);
        width: 133.33333%;
      }
    }
  }
}
