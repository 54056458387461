@mixin app-container {
  // add a generic set of rules to each child page in the router

  // &.is-mobile::ng-deep router-outlet + * {
  &.is-mobile router-outlet + * {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    flex: 1 1 100%;

    section {
      padding: 16px;
      background-color: #F6F6F8; // should come from color palette
      article {
        header:first-of-type {
          margin-top: -24px;
        }
        margin-top: 24px;

        > .header, header {
          margin-bottom: 16px;
          h2.mat-h2 {
            margin: 0;
          }
        }
      }
    }
  }
  // &:not(.is-mobile)::ng-deep router-outlet + * {
  &:not(.is-mobile) router-outlet + * {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    flex: 1 1 100%;

    section {
      background-color: #F6F6F8; // should come from color palette
      padding: 48px;
      article {
        > .header, header {
          margin-bottom: 48px;
          h2.mat-h2 {
            margin: 0;
          }
        }
      }
    }
  }
}