@use '@angular/material' as mat;
@import '../theme-colors.scss';


@mixin mat-button() {

  // Mobile button override
  body.is-mobile {
    button {

      &:not(.mat-icon-button, .mat-button-toggle-button) {
        min-width: 145px !important;
      }
      &:not(.mat-icon-button) {
        border-radius: 56px;
        height: 44px;
      }
      &.mat-icon-button {
        border-radius: 24px;
        height: initial;
        // &:hover {
        //   .mat-button-wrapper {
        //     color: mat.get-color-from-palette($primary) !important;
        //   }
        //   .mat-button-focus-overlay {
        //     // background-color: mat.get-color-from-palette($primary) !important;
        //     opacity: 0 !important;
        //   }
        // }
      }

    }
  }

  // Desktop button override
  body:not(.is-mobile) {
    button {
      &:not(.mat-icon-button, .mat-button-toggle-button, .mat-button) {
        min-width: 200px !important;
      }
      &:not(.mat-icon-button, .mat-button, .mat-menu-item) {
        border-radius: 56px;
        height: 48px;
      }
      &.mat-icon-button {
        &:hover {
          .mat-button-wrapper {
            color: mat.get-color-from-palette($primary) !important;
          }
          .mat-button-focus-overlay {
            // background-color: mat.get-color-from-palette($primary) !important;
            opacity: 0 !important;
          }
        }
      }
    }
  }

  // Device agnostic button styles
  button {
    &.mat-icon-button {
      border-radius: 24px;
      height: initial;
    }
    // Link style buttons do not get a background color on hoever
    &.mat-button {
      .mat-button-focus-overlay {
        background-color: transparent !important;
        opacity: 0 !important;
      }
      &:hover:not(.sad) {
        .mat-button-wrapper {
          color: mat.get-color-from-palette($primary) !important;
        }
      }
    }

    &.mat-stroked-button {
      &.happy {
        border-color: mat.get-color-from-palette($primary) !important;
        background-color: rgba(50, 114, 227, 0.04);
      }
      &.sad {
        color: #606367;
      }
      &:not(.mat-button-disabled) {
        border-color: #8e9193;
      }
    }

    .mat-button-wrapper {
      white-space: normal;

      mat-spinner {
        margin-right: 8px;
        circle {
          stroke: mat.get-color-from-palette($primary, 900);
        }
      }
    }

    &.mat-flat-button, .mat-button {
      color: #1C223F;
      mat-icon {
        margin-right: 8px;
      }
    }

    &.mat-primary.mat-button-disabled {
      background-color: mat.get-color-from-palette($primary) !important;
      opacity: 0.7;
      color: mat.get-color-from-palette($primary, default-contrast)  !important;
    }
  }
}
